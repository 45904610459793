import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./industry-landing.scss";
import {
  getPage,
  TypeGlobalEnterprisesFPage,
  TypeGlobalEnterprisesFQueryResult,
} from "../utils/queries/global_enterprises";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../components/title/title";
import AmazeeIoMetadata from "../components/metadata";
import RichTextRender from "../utils/rich-text";
import AmazeePage from "../components/layout";
import Linked from "../utils/linkedItem";
import { AmazeeIOButton } from "../components/button/button";
import usePreviewData from "../utils/usePreviewData";
import { ResourceInteruption } from "../templates/resources/customers";

const GlobalEnterprisesPage = ({ pageContext, location }: any) => {
  const result = usePreviewData(
    pageContext.data,
    "PrismicGlobalEnterprises",
    true
  ) as TypeGlobalEnterprisesFPage;
  const showPage = true;

  return showPage ? (
    <AmazeePage
      location={location}
      id="global-enterprises"
      classSet="industry-landing"
    >
      <div>
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
          {result.canonical_url && (
            <link rel="canonical" href={result.canonical_url} />
          )}
        </Helmet>
        <PageTitle title={RichTextRender(result.title.text)} />
        <AmazeeIoMetadata
          title={RichTextRender(result.title.text)}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={RichTextRender(result.title)}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="services">
          <div className="container">
            <div className="row">
              {result.services.map((service, index) => (
                <div key={index} className="col-12 col-lg-4 service">
                  <img
                    src={service.service_image.url}
                    alt={service.service_image.alt}
                  />
                  <div className="service-title">{service.service_title}</div>
                  <div className="description">
                    {service.service_description}
                  </div>
                  <div className="border-divider"></div>
                  <div className="description">{service.foot_notes}</div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="protect-your-web-application" className="quote">
          <div className="container">
            <div className="row flex justify-center items-center">
              <div className="col-10 text-left">
                <div className="description">
                  {RichTextRender(result.section_text1)}
                </div>
              </div>
              <div className="col-10 flex justify-start img-wrap">
                <img src={result.image1.url} alt={result.image1.alt} />
                <div className="quote-text">
                  <span className="name">{result.section_title1}</span>
                  <span className="company">{result.section_subtitle1}</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services-lower">
          <div className="container">
            <div className="row">
              {result.services_lower.map((service_lower, index) => (
                <div key={index} className="col-12 col-lg-4 service">
                  <img
                    src={service_lower.service_image_lower.url}
                    alt={service_lower.service_image_lower.alt}
                  />
                  <div className="service-title">
                    {service_lower.service_title_lower}
                  </div>
                  <div className="description">
                    {service_lower.service_description_lower}
                  </div>
                  <div className="border-divider"></div>
                  <div className="description">{service_lower.foot_notes}</div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="cal-section">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-6 text-left">
                <h2>
                  See what ZeroOps application delivery can do for your team and
                  business.
                </h2>
                <h3>Book a 1-on-1 conversation with us today</h3>
                <div className="text-section">
                  <p className="mb-4">
                    What to expect when completing our form:
                  </p>
                  <ul>
                    <li>You’ll book a time with our team straight away</li>
                    <li>
                      Get ready for a conversation tailored to your business and
                      use case
                    </li>
                    <li>
                      We’ll show you how our ZeroOps solution can maximize value
                      for your team, and business
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 text-center flex justify-center">
                <img src="https://images.prismic.io/amazeeio/2fdbb870-6caa-4653-8ee0-1b1b3e373da7_Book-a-Demo.png?auto=compress,format" />
              </div>
            </div>
            <iframe
              title={"hubspot"}
              style={{
                width: "100%",
                height: "800px",
              }}
              src={
                "https://go.amazee.io/meetings/martin-schloegl/zeroops-conversation?embed=true"
              }
            />
          </div>
        </section>
      </div>
    </AmazeePage>
  ) : (
    <div>You can’t access this secret page.</div>
  );
};

export default GlobalEnterprisesPage;
